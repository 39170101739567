import React from 'react'
import { useSlerp } from '@slerp/client'
import { useQuery, useMutation } from '@apollo/client'
import { uuid } from 'uuidv4'
import isEmpty from 'lodash/isEmpty'
import { message, Tabs } from 'antd'
import {
  CREATE_BASIC_APP_SETTINGS,
  QUERY_APP_SETTINGS,
  UPDATE_BASIC_APP_SETTINGS
} from './AppSettingsQueries'
import Campaigns from './Campaigns'
import Designer from './Designer'
import Loading from '../Utils/Loading'
import { BasicAppSettings as BasicAppSettingsType } from 'types'
import { appSettingsSuccessMessage } from './utils'
import { useGlobalQuery } from 'components/Utils/useGlobalQuery'

const { TabPane } = Tabs

const DEFAULT_FORM_VALUES = {
  hide_status_bar: null,
  footer_nav: null,
  splash_additional_button_text: '',
  splash_additional_button_link: '',
  location_products_view: ''
}

const PushNotificationCampaigns = () => {
  const { user } = useSlerp()
  const { isLoading } = useGlobalQuery()
  const { data, loading, refetch } = useQuery(QUERY_APP_SETTINGS, {
    variables: { merchantId: user.merchant.id },
    fetchPolicy: 'no-cache'
  })

  const appSettings = data?.app_settings[0]?.id
    ? data?.app_settings[0]
    : DEFAULT_FORM_VALUES
  const [createBasicAppSettings] = useMutation(CREATE_BASIC_APP_SETTINGS, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      { query: QUERY_APP_SETTINGS, variables: { merchantId: user.merchant.id } }
    ]
  })
  const [updateBasicAppSettings, { loading: isLoadingUpdateBasicAppSettings }] =
    useMutation(UPDATE_BASIC_APP_SETTINGS, {
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: QUERY_APP_SETTINGS,
          variables: { merchantId: user.merchant.id }
        }
      ]
    })

  const onChange = (values: BasicAppSettingsType) => {
    if (!isEmpty(appSettings?.id)) {
      updateBasicAppSettings({
        variables: {
          updateValues: { ...values },
          id: appSettings.id
        }
      })
        .then(() => {
          const key = Object.keys(values)[0]
          const value = Object.values(values)[0]

          message.success(appSettingsSuccessMessage(key, value), 2)
        })
        .catch((error) => {
          message.destroy()
          message.error(
            `Unable to update app settings due to ${error.message}.`,
            3
          )
        })
    } else {
      let newValues = { ...values }

      createBasicAppSettings({
        variables: {
          object: {
            ...newValues,
            id: uuid(),
            merchant_id: user.merchant.id,
            inserted_at: 'now()',
            updated_at: 'now()'
          }
        }
      })
        .then(() => refetch())
        .catch((error) => {
          message.destroy()
          message.error(
            `Unable to create app settings due to ${error.message}.`,
            3
          )
        })
    }
  }

  if (loading || isLoading || isLoadingUpdateBasicAppSettings)
    return <Loading />

  return (
    <Tabs
      type='card'
      defaultActiveKey='1'
      data-testid='push-notification-campaigns-tabs'
    >
      <TabPane
        key='1'
        tab={
          <a data-testid='push-notification-campaigns-tab-pane'>CAMPAIGNS</a>
        }
      >
        <Campaigns />
      </TabPane>
      <TabPane
        key='2'
        tab={<a data-testid='app-designer-tab-pane'>DESIGNER</a>}
      >
        <Designer appSettings={appSettings} onChange={onChange} />
      </TabPane>
    </Tabs>
  )
}

export default PushNotificationCampaigns
